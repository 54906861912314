'use strict';

const getURLParams = locationString => {
	try {
		const hashes = locationString.slice(locationString.indexOf('?') + 1).split('&');
		const params = {};
		hashes.map(hash => {
			const [key, val] = hash.split('=');
			params[key] = decodeURIComponent(val);
		});
		return params;
	} catch (error) {
		return { error: 'Error fetching url params' };
	}
};

module.exports = getURLParams;
