var _iub = _iub || [];

_iub.csConfiguration = {
	lang: 'en',
	siteId: 1807825,
	gdprAppliesGlobally: false,
	consentOnScroll: false,
	whitelabel: false,
	cookiePolicyId: 47439358,
	banner: {
		acceptButtonDisplay: true,
		customizeButtonDisplay: true,
		position: 'float-top-center',
		textColor: 'white',
		backgroundColor: '#333333',
		acceptButtonColor: '#e5014d',
		acceptButtonCaptionColor: 'white',
		customizeButtonColor: '#212121',
		customizeButtonCaptionColor: 'white',
	},
};
typeof window !== 'undefined' ? (window._iub = _iub) : null;
