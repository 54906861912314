module.exports = {
	baseURL: 'https://staging.dochq.co.uk/',
	baseAPI: 'https://staging.dochq.co.uk/api/',
	jsonAPI: 'https://api-staging.dochq.co.uk',
	bookingAPI: 'https://dochq-booking-api.dochq.co.uk',
	discountAPI: 'https://services-discounts-staging.dochq.co.uk/',
	covid19API: 'https://services-covid-19-staging.dochq.co.uk/',
	contactAPI: 'https://api-staging.dochq.co.uk/v1/contact',
	brochureAPI: 'https://hooks.zapier.com/hooks/catch/4296899/orfbark/',
	shopIframeUrl: 'https://airportal-staging.dochq.co.uk/rapid-antigen-testing-order',
	featureFlags: {},
};
