import React from 'react';
import './LinkButton.scss';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*
	Acceptable colors - pink or green
*/
const LinkButton = ({ text, color, linkSrc, newTab, style }) => {
	if (linkSrc.includes('https://') || linkSrc.includes('http://')) {
		return (
			<a href={linkSrc} target='_blank' rel='noopener noreferrer' target={newTab ? '_blank' : ''}>
				<button type='button' style={style} role='button' className={`btn btn-swu-${color}`}>
					{text}
				</button>
			</a>
		);
	} else {
		return (
			<Link to={linkSrc} target={newTab ? '_blank' : ''}>
				<button type='button' style={style}  role='button' className={`btn btn-swu-${color}`}>
					{text}
				</button>
			</Link>
		);
	}
};
export default LinkButton;

LinkButton.defaultProps = {
	text: 'DocHQ',
	linkSrc: 'https://dochq.co.uk',
	color: 'pink',
	newTab: false,
};

LinkButton.propTypes = {
	text: PropTypes.string.isRequired,
	linkSrc: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	newTab: PropTypes.bool.isRequired,
};
