import React, { Component } from 'react';
import './NewNavBar.scss';
import { Link } from 'gatsby';
import LinkButton from '../LinkButton/LinkButton';
import getURLParams from '../../helpers/getURLParams';

const logo = require('../../assets/icons/dochq-logo-rect.svg');
const synLabLogo = require('../../assets/icons/synlab-logo.png');
const menuIcon = require('../../assets/icons/menu.svg');
const styles = { active: { color: 'var(--doc-pink)', fontWeight: 'bold' } };

const CHECKOUT_LINKS = [
	'/checkout',
	'/checkout/',
];

const HIDE_NAV_LINKS = [
	...CHECKOUT_LINKS,
	'/synlab-shop/',
	'/partners/insurefor',
	'/partners/insurefor/'
];

export default class NewNavBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false,
			addedAwin: false,
			topActive: 'personal',
		};
		this.getURLParams = getURLParams.bind(this);
		this.handleScroll = handleScroll.bind(this);

		function handleScroll(event) {
			// User has scrolled, hide dropdown;
			if (this.state.isMobileVisible) {
				this.setState({ isMobileVisible: false });
			}
		}
	}

	componentWillMount() {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', this.handleScroll);
		}
	}

	componentDidUpdate() {
		if (typeof window !== 'undefined') {
			let trustpilotbox = document.getElementById('trustpilot_nav');
			window.Trustpilot.loadFromElement(trustpilotbox);
		}
	}

	render() {
		if (this.state.addedAwin === false && typeof window !== 'undefined') {
			let body = document.getElementsByTagName('body')[0];

			let awMastertag = document.createElement('script');
			awMastertag.setAttribute('defer', 'defer');
			awMastertag.src = 'https://www.dwin1.com/19181.js';
			awMastertag.type = 'text/javascript';

			body.appendChild(awMastertag);

			this.setState({ addedAwin: true });
		}
		if (
			typeof sessionStorage !== 'undefined' &&
			typeof sessionStorage.getItem('cookiesShown') !== 'undefined' &&
			sessionStorage.getItem('cookiesShown') !== true
		) {
			var _iub = _iub || [];

			_iub.csConfiguration = {
				lang: 'en',
				siteId: 1807825,
				gdprAppliesGlobally: false,
				consentOnScroll: false,
				whitelabel: false,
				cookiePolicyId: 47439358,
				banner: {
					acceptButtonDisplay: true,
					customizeButtonDisplay: true,
					position: 'float-top-center',
					textColor: 'white',
					backgroundColor: '#333333',
					acceptButtonColor: '#e5014d',
					acceptButtonCaptionColor: 'white',
					customizeButtonColor: '#212121',
					customizeButtonCaptionColor: 'white',
				},
			};
			typeof window !== 'undefined' ? (window._iub = _iub) : null;
			sessionStorage.setItem('cookiesShown', true);
		}

		return (
			<React.Fragment>
				<nav role='navigation'>
					<div className='navigation-container'>
						<div className="row" style={{ width: '100%' }}>
							<div className="col col-sm-6 col-md-4 col-lg-4 trust-pilot-container">
								<Link to='/'>
									<img src={logo} alt='DocHQ Logo' style={{ paddingLeft: 20 }} />
								</Link>
								{/* {(!this.props.path.includes('/shop') && !this.props.path.includes('/checkout') && !this.state.isMobileVisible) && (
									<div className="row trust-pilot-block">
										<div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
											<a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener"></a>
										</div>
									</div>
								)} */}
							</div>
							{CHECKOUT_LINKS.includes(this.props.path) && (
								<div className="col col-sm-6 col-md-6 col-lg-8" style={{ padding: 0 }}>
									<div className="row" style={{ width: '100%', placeContent: 'center flex-end', height: '100%' }}>
										<div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
											<a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener"></a>
										</div>
									</div>
								</div>
							)}
							{!HIDE_NAV_LINKS.includes(this.props.path) && (
								<div className="col col-sm-6 col-md-6 col-lg-8 full-size-on-large" style={{ padding: 0 }}>
									<div className={`navigation-items ${this.state.isMobileVisible ? 'mobile-nav' : ''}`}>
										<div className={`row ${this.state.isMobileVisible ? 'column' : ''}`}>
											<a
												href='https://dochq.co.uk/shop'
												target='_blank'
												rel='noopener noreferrer'
												aria-label='DocHQ Blog'
											>
												<div className='nav-item' style={{ paddingLeft: 5 }}>English</div>
											</a>
											<Link to='/' active activeStyle={styles.active}>
												<div className='nav-item'>German</div>
											</Link>
											{(!this.props.path.includes('/shop') && !this.props.path.includes('/checkout') && this.state.isMobileVisible) && (
												<div style={{ width: '100%' }}>
													<div className="row">
														<div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
															<a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener"></a>
														</div>
													</div>
												</div>
											)}
										</div>
										{/* <div>
											<LinkButton text='Login' linkSrc='https://login.dochq.co.uk' />
										</div> */}
									</div>
									<div
										className='menu-bar display-on-sm'
										role='navigation'
										onClick={() => {
											this.setState(prevState => ({ isMobileVisible: !prevState.isMobileVisible }));
										}}
									>
										{this.state.isMobileVisible ? (
											<i className='fa fa-chevron-right fa-2x'></i>
										) : (
											<i className="fas fa-bars" />
										)}
									</div>
								</div>
							)}
							{(this.props.path.includes('/synlab-shop') && !this.state.isMobileVisible) && (
								<div className="synlab-logo-box" style={{ padding: 0 }}>
									<img src={synLabLogo} alt='SynLab Logo' style={{ paddingLeft: 20, height: 'unset' }} />
								</div>
							)}
						</div>
					</div>
				</nav>
			</React.Fragment>
		);
	}
}
/*
          Business home
              how it works
							partnerships
							pricing
              faq
              contact
              |
              personal

          Personal home
              travel vaccines
							travel medicines
							about us
 */
