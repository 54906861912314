import React from 'react';
import './Footer.scss';

const docLogoRect = require('../../assets/icons/dochq-logo-rect-white.svg');
const icoLogo = require('../../assets/icons/ico-logo.svg');
const applePodcasts = require('../../assets/icons/apple-podcasts.svg');
const spotifyPodcasts = require('../../assets/icons/spotify-podcasts.svg');

const Footer = () => (
	<div className='footer-container'>
		<div className='row '>
			<div className='col-lg-3 col-md-12 logo-container'>
				<img className='svglogo' src={docLogoRect} alt='DocHQ Logo' />
				<div className='dochq-socials'>
					<a
						alt='DocHQ Facebook'
						href='https://www.facebook.com/DocHQ.co.uk'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Facebook'
					>
						<i className='fab fa-facebook-f fa-2x'></i>
					</a>
					<a
						alt='DocHQ Twitter'
						href='https://twitter.com/@DocHQ_Health'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Twitter'
					>
						<i className='fab fa-twitter fa-2x'></i>
					</a>
					<a
						alt='DocHQ Instagram'
						href='https://www.instagram.com/dochq'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Instagram'
					>
						<i className='fab fa-instagram fa-2x'></i>
					</a>
					<a
						alt='DocHQ LinkedIn'
						href='https://www.linkedin.com/company/14136494'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ LinkedIn'
					>
						<i className='fab fa-linkedin-in fa-2x'></i>
					</a>
				</div>
				<div className='row' style={{ paddingTop: '20px' }}>
					<a
						alt='DocHQ Spotify'
						href='https://open.spotify.com/show/0qdBL5c0G1tAu2WgtO6PrP?si=bHRWLCgPQ2ioT79Wgy-dcQ'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Spotify'
					>
						<img src={spotifyPodcasts} alt='Spotify Logo' style={{height:"35px"}} />
					</a>
				</div>
				<div className='row' style={{ paddingTop: '5px' }}>
					<a
						alt='DocHQ Apple Podcast'
						href='https://podcasts.apple.com/us/podcast/health-tech-for-businesses/id1526658913'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Apple Podcast'
					>
						<img src={applePodcasts} alt='Apple Logo' style={{height:"35px"}}/>
					</a>
				</div>
			</div>

			<div className='col-lg-2 col-md-3 col-sm-12'>
				<div className='link-item'>
					<a
						href='https://blog.dochq.co.uk'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Blog'
					>
						Blog
					</a>
				</div>
				<div className='link-item'>
					<a
						href='https://dochq.co.uk/about-us'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ About Us'
					>
						About Us
					</a>
				</div>
				<div className='link-item'>
					<a
						href='https://dochq.co.uk/contact-us'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ About Us'
					>
						Contact Us
					</a>
				</div>
			</div>
			<div className='col-lg-2 col-md-3 col-sm-12'>
				<div className='link-item'>
					<a
						href='https://dochq.co.uk/terms-and-conditions'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Terms and Conditions'
					>
						Terms and Conditions
					</a>
				</div>
				<div className='link-item'>
					<a
						href='https://dochq.co.uk/privacy-policy'
						target='_blank'
						rel='noopener noreferrer'
						aria-label='DocHQ Privacy Policy'
					>
						Privacy Policy
					</a>
				</div>
			</div>
			<div className='col-lg-1 col-md-12 col-sm-12'>
				{/*<a
						alt="Information Commissioner's Office"
						href='https://ico.org.uk/'
						target='_blank'
						rel='noopener noreferrer'
						aria-label="Information Commissioner's Office"
					>*/}
				<img className='icologo' src={icoLogo} alt='ICO Logo' />
				{/*</a>*/}
			</div>
		</div>
		<div className='row copyright'>
			<p>
				<strong>dochq.de</strong> is owned and operated by DocHQ Ltd, UK
			</p>
		</div>
	</div>
);

export default Footer;
